<template>
  <div id="helpjuice_js_widget_content">
    <img src="https://helpjuice.com/images/cube-theme/loading.gif" class="helpjuice-loading-spinner">
  </div>
</template>

<script>
const js = document.createElement('script')
js.src = 'https://s3.amazonaws.com/assets.helpjuice.com/helpjuice.min.js'
document.getElementsByTagName('body')[0].appendChild(js)
</script>

<style>
#helpjuice_js_widget_content {
    padding: 0px;
}
#helpjuice_js_widget_content iframe {
    width: 100%;
    position: relative;
}
.vertical-nav {
    display: none;
}
.header {
    display: none;
}
.layout-container .container .main {
    padding: 0px;
}
</style>
